import 'grapesjs/dist/css/grapes.min.css';
// import gjspresetwebpage from 'grapesjs-preset-webpage';
import gjsblockbasic from 'grapesjs-blocks-basic'
import {GrapesjsReact} from 'grapesjs-react';
import gjspresetnewsletter from 'grapesjs-preset-newsletter';
// import gjsmjml from 'grapesjs-mjml';

const GrapesEditor = (props) => {
    
  return <GrapesjsReact
    id='grapesjs-react'
    onInit={(editor) => {
        editor.on('change:changesCount', e => {
            const htmlWithInlineCSS = editor.runCommand('gjs-get-inlined-html');
            props.onChange(htmlWithInlineCSS);
        });

        editor.on('load', e => {
            if (props.initialContent) {
                // Add initial content if provided
                editor.setComponents(props.initialContent);
            }
        });
    }}
    plugins={[
      gjspresetnewsletter,
      gjsblockbasic
    ]}
  />;
};

export default GrapesEditor;