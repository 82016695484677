import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

const processDataDynamicBins = (data, binCount) => {
    const max = Math.max(...data);
    const min = Math.min(...data);
    const binSize = (max - min) / binCount;
    let frequency = new Array(binCount).fill(0);

    data.forEach(value => {
        const index = Math.min(Math.floor((value - min) / binSize), binCount - 1);
        frequency[index]++;
    });

    return frequency.map((v, i) => ({
        name: `${Math.floor(min + i * binSize)} - ${Math.floor(min + (i + 1) * binSize)}`,
        frequency: v,
    }));
};

const processData10StaticBins = (data) => {
    let frequency = new Array(10).fill(0);

    data.forEach(value => {
        const index = value > 10 ? 9 : value - 1;
        frequency[index]++;
    });

    return frequency.map((v, i) => ({
        // For the last bin, name it "10+"
        name: (i < 9) ? `${i + 1}` : `${i + 1}+`,
        frequency: v,
    }));
};

const processData = (data) => {
    let frequency = new Array(5).fill(0);

    data.forEach(value => {
        const index = value > 5 ? 4 : value - 1;
        frequency[index]++;
    });

    return frequency.map((v, i) => ({
        // For the last bin, name it "5+"
        name: (i < 4) ? `${i + 1}` : `${i + 1}+`,
        frequency: v,
    }));
};



const FrequencyDistributionChart = ({ data, binCount }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(processData10StaticBins(data, binCount));
    }, [data, binCount]);

    return (
        <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="frequency" />
            <Tooltip />
            <Legend />
            <Bar dataKey="frequency" fill="#8884d8" />
        </BarChart>
    );
};

export default FrequencyDistributionChart;
