import React, { useState, useEffect } from "react";
// import TinyMCEEditor from "../../components/tinyMCE/TinyMCEEditor";
import { apiUrl, cloudRunApiUrl } from "../../globalVariables";
import Modal from 'react-bootstrap/Modal';
import GrapesEditor from "../../components/GrapesJS/GrapesEditor";

// Get the ENV variable REACT_APP_ADMIN_TOKEN from the .env file
const { REACT_APP_ADMIN_TOKEN, REACT_APP_IMG_HIPPO_API_KEY } = process.env;

const MarketingPage = () => {
    const [emailHtml, setEmailHtml] = useState("");
    const [previewMode, setPreviewMode] = useState("desktop");
    const [query, setQuery] = useState("");
    const [customTokens, setCustomTokens] = useState("email==email");
    const [subject, setSubject] = useState("");
    const [formattedCustomTokens, setFormattedCustomTokens] = useState({});
    const [formattedQuery, setFormattedQuery] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);

    const [sendingEmail, setSendingEmail] = useState(false);
    const [sentEmailCount, setSentEmailCount] = useState(0);

    const allowedCollections = ["users"];
    const [selectedCollection, setSelectedCollection] = useState(allowedCollections[0]);

    const subscriptionGroups = ["product-updates", "tips-and-tricks",
        "promotions", "survey", "customer-feedback", "beta-invitations",
        "onboarding", "important-account-updates",]
    const [selectedSubscriptionGroup, setSelectedSubscriptionGroup] = useState(subscriptionGroups[0]);

    const senders = ["\"Addy AI\" <info@addy-ai.com>", "\"Michael Vandi\" <info@addy-ai.com>",
        "\"Michael Vandi\" <michael@addy-ai.com>"
    ];
    const [selectedSender, setSelectedSender] = useState(senders[0]);
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const parseQuery = (input) => {
        const lines = input.split('\n').map(l => l.trim()).filter(Boolean);
        const filterKeys = [];
        const filterValues = [];
        const operators = [];

        lines.forEach(line => {
            // Adjust this regex if you want to allow other operators like !=, >=, etc.
            const match = line.match(/^(.*?)\s*(==)\s*(.+)$/);
            if (!match) return;

            const [, rawKey, rawOp, rawVal] = match.map(s => s.trim());
            let value;

            if (/^".+"$/.test(rawVal)) {
                // Remove surrounding quotes
                value = rawVal.slice(1, -1);
            } else if (rawVal === 'true') {
                value = true;
            } else if (rawVal === 'false') {
                value = false;
            } else if (!isNaN(rawVal)) {
                // Parse as number (including decimals)
                value = parseFloat(rawVal);
            } else {
                // Fallback (if it doesn't match any of the above)
                value = rawVal;
            }

            filterKeys.push(rawKey);
            operators.push(rawOp);
            filterValues.push(value);
        });

        const returnObj = {
            filterKeys,
            filterValues,
            operators
        }
        return returnObj;
    };

    const handleClose = () => setShow(false);
    const handleSuccessClose = () => setShowSuccess(false);

    const isValidBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    const handleBase64ImagesInHTML = async (html) => {
        const imgurRegex = /src="data:image\/[^;]+;base64,([^"]+)"/g;
        const imgurMatches = html.match(imgurRegex);
        if (!imgurMatches) return html;
    
        let replacedHtml = html; // create a new variable for making replacements
    
        for (const match of imgurMatches) {
            const base64Match = match.match(/base64,([^"]+)/);
            if (base64Match) {
                const base64Image = base64Match[1];
                
                // Upload the image to cloud and get the imgur URL
                const imgurUrl = await uploadImageToCloud(base64Image);
    
                // Replace the first occurrence of base64 image with imgur url
                replacedHtml = replacedHtml.replace(match, `src="${imgurUrl}"`);
            }
        }
    
        return replacedHtml;
    };

    const uploadImageToCloud = async (base64Image) => {
        
        // Convert base64 to Blob
        if (!isValidBase64(base64Image)) {
            alert("Invalid base64 image");
            return;
        }
        const binaryString = window.atob(base64Image);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
      
        const imageBlob = new Blob([bytes.buffer], { type: 'image/png' });
      
        // FormData
        const formData = new FormData();
        formData.append('file', imageBlob);
        formData.append('api_key', REACT_APP_IMG_HIPPO_API_KEY);
        
        const response = await fetch(`https://api.imghippo.com/v1/upload`, {
          method: "POST",
          body: formData,
        });
        
        const data = await response.json();

        if (!data?.success) {
          alert("Failed to upload image to cloud storage");
          throw new Error("Failed to upload image to imgur");
        }
        return data?.data?.url;
    };
      

    const sendEmail = async () => {
        if (!filteredUsers.length) {
            alert("⚠️ No recipients to send email to");
            return;
        }
        if (!subject) {
            alert("⚠️ Please enter a subject");
            return;
        }
        // Send email to each recipient
        const emailDetails = [];
        for (let i = 0; i < filteredUsers.length; i++) {
            const user = filteredUsers[i];
            const email = user.email; // Assuming email is always present. TODO: Add a check for this
            // Replace custom tokens in the emailHtml
            let emailHtmlCopy = emailHtml;
            Object.keys(formattedCustomTokens).forEach((key, index) => {
                const value = formattedCustomTokens.filterValues[index];
                emailHtmlCopy = emailHtmlCopy.replace(new RegExp(`{{${key}}}`, 'g'), user[value]);
            });
            // Replace all "{{uid}}" with user.id
            emailHtmlCopy = emailHtmlCopy.replaceAll("{{uid}}", user.id);
            // Handle base64 images in the emailHtml
            emailHtmlCopy = await handleBase64ImagesInHTML(emailHtmlCopy);

            // Now add all the details
            emailDetails.push({
                from: "",
                to: email,
                subject: subject,
                emailHTML: emailHtmlCopy,
                emailPlainText: HTMLToText(emailHtmlCopy),
                emailKind: selectedSubscriptionGroup,
            })
        }
        // Now send the emails using sendgrid
        setSendingEmail(true);
        await sendHTMLEmailViaSendGrid(emailDetails);
        setSendingEmail(false);
        // Hide the modal and show success modal
        setShow(false); // Hide the modal
        setShowSuccess(true); // Show success modal
    }

    /**
     * 
     * @param {String} HTML 
     * @return {String} Text content of the html
     */
    const HTMLToText = (HTML) => {
        const element = document.createElement('div');
        element.innerHTML = HTML;
        return element.textContent || element.innerText || '';
    }

    useEffect(() => {
        // Parse the custom tokens and query on first load
        const firstTimeParsedCustomTokens = parseQuery(customTokens);
        setFormattedCustomTokens(firstTimeParsedCustomTokens);
    }, []);

    const getUsersFromDb = async (query, customTokens) => {
        // If no filter keys, return empty array
        if (!query?.filterKeys?.length) return [];
        setUsersLoading(true);
        const endpoint = `${apiUrl}/admin/filter-collection`;
        const dataToSend = {
            collectionName: selectedCollection,
            filterKeys: query.filterKeys,
            filterValues: query.filterValues,
            operators: query.operators,
            customTokensKeys: customTokens.filterKeys,
            customTokensValues: customTokens.filterValues,
            customTokensOperators: customTokens.operators,
            token: REACT_APP_ADMIN_TOKEN,
        }
        // Make a post request to the endpoint using fetch and get the data key from the response
        fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
        }).then((response) => response.json())
            .then((response) => {
                if (!response.success) {
                    console.error(response.reason);
                    throw new Error("Failed to get users from the database");
                }
                const fetchedData = response.data;
                setFilteredUsers(fetchedData);
                setUsersLoading(false);
            }).catch((error) => {
                // TODO: Log this error
                console.error(error);
                setUsersLoading(false);
            });
        return [];
    }

    const sendHTMLEmailViaSendGrid = async (emails) => {

        try {
            const payload = {
                token: REACT_APP_ADMIN_TOKEN,
                emails,
            }
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            };

            const ENDPOINT = `${cloudRunApiUrl}/admin/send-email-batch`;

            const response = await fetch(ENDPOINT, requestOptions);
            const reader = response.body.getReader();

            if (response.status !== 200) {
                console.error(`Failed to send email. Status: ${response.status}`);
                return;
            }

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }

                const chunkString = new TextDecoder().decode(value);
                if (!isJSONString(chunkString)) {
                    console.error("Response received is not a JSON", chunkString);
                    break;
                }

                // If the chunk is string then parse it
                const responseData = JSON.parse(chunkString);
                if (responseData?.success) {
                    // Successfully sent email
                    console.log("Successfully sent email")
                    setSentEmailCount(sentEmailCount + 1); // Increment the count
                } else {
                    console.log("Failed to send email", responseData);
                }
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }

        return true;
    };

    const isJSONString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            //   console.error("Error parsing JSON string", str, e);
            return false;
        }
        return true;
    }


    useEffect(() => {
        // Every time formattedCustomTokens or formattedQuery changes
        // filter the users with debouncing.
        const delayDebounceFn = setTimeout(async () => {
            await getUsersFromDb(formattedQuery, formattedCustomTokens);
        }, 300);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedCustomTokens, formattedQuery]);

    return (
        <div className="container-fluid p-4">
            <h1>Marketing Tools</h1>
            <br />
            <h3 className="mb-4">Send email to users</h3>

            <div className="row gap-2 container-fluid">
                {/* Rich text editor */}
                <div className="col border p-3 rounded">
                    <h5>Email subject</h5>
                    <input
                        type="text"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <br />
                    <h5>Email content</h5>
                    <GrapesEditor
                        initialContent={`<div style="padding: 20px;">${defaultBrandedHeader}<p>Start editing here...</p>${defaultBrandedFooter}</div>`}
                        onChange={(html) => setEmailHtml(html)}
                    />
                    {/* <TinyMCEEditor
                        // value={emailHtml}
                        initialValue={`${defaultBrandedHeader}<p>Start editing here...</p>${defaultBrandedFooter}`}
                        onEditorChange={(content) => setEmailHtml(content)}
                        init={{
                            height: 500,
                            plugins: [
                                "advlist autolink lists link image charmap preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste help wordcount",
                            ],
                            toolbar:
                                "undo redo | formatselect | bold italic backcolor | " +
                                "alignleft aligncenter alignright alignjustify | " +
                                "bullist numlist outdent indent | removeformat | help | image | emoticons | link",
                        }}
                    /> */}
                </div>

                {/* Toggle + Preview */}
                {/* <div className="col border p-3 d-flex flex-column align-items-center rounded">
                    <div className="mb-3 switch-toggle-container">
                        <button
                            className={`btn me-2 ${previewMode === "desktop" ? "btn-purple" : "btn-purple-outline"
                                }`}
                            onClick={() => setPreviewMode("desktop")}
                        >
                            Desktop
                        </button>
                        <button
                            className={`btn ${previewMode === "mobile" ? "btn-purple " : "btn-purple-outline"
                                }`}
                            onClick={() => setPreviewMode("mobile")}
                        >
                            Mobile
                        </button>
                    </div>

                    {previewMode === "desktop" && (
                        <div
                            className="border rounded p-2"
                            style={{ width: "100%", minHeight: "300px", overflow: "auto" }}
                            dangerouslySetInnerHTML={{ __html: emailHtml }}
                        />
                    )}

                    {previewMode === "mobile" && (
                        <div
                            className="border rounded p-2"
                            style={{ width: "360px", minHeight: "400px", overflow: "auto" }}
                            dangerouslySetInnerHTML={{ __html: emailHtml }}
                        />
                    )}
                </div> */}
            </div>

            <br />

            <div>
                <br />
                <h5>Who to send it to</h5>
                <br />
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column gap-1">
                        {/* Dropdown select to select the database collection to choose from */}
                        <h6>Database collection</h6>
                        <select
                            className="form-select"
                            value={selectedCollection}
                            onChange={(e) => setSelectedCollection(e.target.value)}
                        >
                            {allowedCollections.map((collection) => (
                                <option key={collection} value={collection}>
                                    {collection}
                                </option>
                            ))}
                        </select>

                    </div>

                    <div className="d-flex flex-column gap-1">
                        <h6>Query</h6>
                        {/* Text area where users can enter a db query with where clause and new lines */}
                        <textarea
                            className="form-control"
                            rows="5"
                            value={query}
                            placeholder={`The firestore query to filter with. Example:\nemail=="example@example.com"\ncreatedAccountOnClient==true`}
                            onChange={(e) => {
                                setQuery(e.target.value);
                                const parsedQuery = parseQuery(e.target.value);
                                if (Object.keys(parsedQuery).length) {
                                    setFormattedQuery(parsedQuery);
                                }

                            }}
                        />
                    </div>

                    <div className="d-flex flex-column gap-1">
                        <h6>Custom tokens</h6>
                        {/* Text area where users can replace custom tokens like firstName, etc */}
                        <textarea
                            className="form-control"
                            rows="5"
                            value={customTokens}
                            placeholder={`Replace custom tokens like {{firstName}} with actual keys in the document objct. Example:\nemail==email\nfirstName==name`}
                            onChange={(e) => {
                                setCustomTokens(e.target.value);
                                const parsedCustomTokens = parseQuery(e.target.value);
                                if (Object.keys(parsedCustomTokens).length) {
                                    setFormattedCustomTokens(parsedCustomTokens);
                                }
                            }}
                        />
                    </div>

                    <div className="d-flex flex-column gap-1">
                        <h6>Subscription group</h6>
                        <select
                            className="form-select"
                            value={selectedSubscriptionGroup}
                            onChange={(e) => setSelectedSubscriptionGroup(e.target.value)}
                        >
                            {subscriptionGroups.map((group) => (
                                <option key={group} value={group}>
                                    {group}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex flex-column gap-1">
                        <h6>Sender</h6>
                        <select
                            className="form-select"
                            value={selectedSender}
                            onChange={(e) => setSelectedSender(e.target.value)}
                        >
                            {senders.map((sender) => (
                                <option key={sender} value={sender}>
                                    {sender}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{
                        minHeight: "48px",
                    }}>
                        {usersLoading ? <p>Loading recipients...</p> :
                            <div className="d-flex gap-3 align-items-center">
                                <h6>Send to {filteredUsers.length} recipient(s)</h6>
                                <button
                                    className="btn btn-purple-outline" disabled={true}>
                                    Preview recipients (coming soon)
                                </button>
                            </div>}
                    </div>


                </div>

            </div>
            <br />
            <div>
                <button className="btn btn-purple"
                    onClick={() => setShow(true)}
                >
                    Send email
                </button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {sendingEmail ? <Modal.Title>Sending email</Modal.Title>
                        :
                        <Modal.Title>Are you sure you want to send email?</Modal.Title>}
                </Modal.Header>
                {sendingEmail ?
                    <Modal.Body>Sending {sentEmailCount}/{filteredUsers.length} emails...
                    </Modal.Body>

                    : <Modal.Body>You're about to email {filteredUsers.length} receipients.
                        Double check the email content and the receipients before sending.
                    </Modal.Body>
                }
                <Modal.Footer>
                    <button className="btn btn-purple-outline" onClick={handleClose}>
                        Cancel
                    </button>
                    {!sendingEmail && <button className="btn btn-purple" onClick={sendEmail}>
                        Send email
                    </button>}
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccess} onHide={handleSuccessClose}>
                <Modal.Header closeButton>
                        <Modal.Title>✅ Successfully sent {sentEmailCount} emails</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        You can close this modal now.
                    </Modal.Body>
                
                <Modal.Footer>
                    <button className="btn btn-purple" onClick={() => {
                        handleSuccessClose();
                        setSentEmailCount(0);
                    }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

const defaultBrandedFooter = `<table class="paragraph_block block-18" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word; border-top: 1px solid #dddddd;padding-bottom:30px;padding-left:10px;padding-right:10px;padding-top:30px;margin-top:30px;">
    <tr>
        <td class="pad" style="padding-bottom:30px;padding-left:10px;padding-right:10px;padding-top:10px;">
            <div style="color:#444a5b;direction:ltr;font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;font-size:12px;font-weight:400;letter-spacing:0px;line-height:120%;text-align:center;mso-line-height-alt:14.399999999999999px;">
                <p style="margin: 0; margin-bottom: 16px;">Addy AI, Inc.</p>
                <p style="margin: 0;">945 Market Street, Suite 501, San Francisco, CA 94103<br><br><a href="https://addy.so/email-preferences?uid={{uid}}" target="_blank" style="text-decoration: underline; color: #444a5b;" rel="noopener">Unsubscribe</a></p>
            </div>
        </td>
    </tr>
</table>`;

const defaultBrandedHeader = `<!-- Logo and Company Name (Horizontal) -->
<table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="text-align:center;">
    <tr>
        <td style="padding-top:10px; padding-bottom:30px;">
            <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation"
                    style="display: flex; align-items: center;">
                <tr style="display: flex; align-items: center;">
                    <td style="vertical-align:middle;">
                        <img src="https://i.imgur.com/gVdnANB.png" alt="Addy AI Logo"
                                style="height:24px; display:block;">
                    </td>
                    <td style="vertical-align:middle; padding-left:10px; font-family:Arial,Helvetica,sans-serif; color:#444a5b; font-size:19px; font-weight:bold;">
                        Addy AI
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>`;

export default MarketingPage;
