import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import { ThreeDots } from "react-loader-spinner";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { ResponsiveContainer } from 'recharts';
import FrequencyDistributionChart from '../Graphs/FrequencyDistributionChart';
import { apiUrl } from '../../globalVariables';


class WeekdayDistributionOfEmailVolume extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        const adminMetrics = this.props.adminMetrics ?
            this.props.adminMetrics : {};
        this.state = {
            data: {
                "cohort": {

                }
            },
            currentData: [],
            dataLoading: true,
            user: user,
            options: [],
            defaultOption: "",
            adminMetrics: adminMetrics,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount = async () =>  {
        const cohortList = this.getCohortList();
        if (cohortList && cohortList.length) {
            const firstMonthData = await this.getCohortData(cohortList[0]);
            // Get the list of cohorts
            this.formatNormalDistributionData(firstMonthData["data"], cohortList, cohortList[0]);
        }
    }

    getCohortList = () => {
        const todaysDate = new Date();
        const oldestMonthYear = "1-2023";
        const [month, year] = oldestMonthYear.split('-').map(Number);

        // Set the initial date as given oldest date
        let date = new Date(year, month - 1);

        const listOfMonthYears = [];

        while (date <= todaysDate) {
            // push the formatted date into list
            listOfMonthYears.push(`${date.getMonth() + 1}-${date.getFullYear()}`);
            date.setMonth(date.getMonth() + 1); // increment by one month
        }

        // Reverse the list to get it in required order
        listOfMonthYears.reverse();

        return listOfMonthYears;
    }


    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };
    _onSelect = async (cohort) => {
        const cohortName = cohort.value;
        // Set the data to display
        const monthData = await this.getCohortData(cohortName);
        this.setState({
            currentData: monthData["data"],
        });
    }

    getCohortData = async (month) => {
        // URL and parameters setup
        const url = new URL(apiUrl + "/admin/get-weekday-volume");
        const params = { userId: this.props.user.uid, month: month };

        Object.keys(params).forEach(
            (key) => url.searchParams.append(key, params[key]),
        );

        try {
            // Await for fetch call
            const response = await fetch(url);

            if (!response.ok) { // if HTTP-status is 200-299
                console.log("HTTP-Error: " + response.status);
                return;
            }

            const data = await response.json();

            const collection = data.data;

            // Checking Data Validity
            if (!collection || collection == null ||
                !data.metadata || data.metadata.result !== "success") {
                // TODO: Log this error
                return;
            } else {
                return collection;
            }

        } catch (error) {
            console.error(error);
            return undefined;
        }
    };



    formatNormalDistributionData = (d, cohortList, month) => {
        const currentData = {
            ...this.state.data
        };

        
        for (let i = 0; i < cohortList.length; i++) {
            const cohortName = cohortList[i];
            if (cohortName === month) {
                currentData["cohort"][cohortName] = d;
            }
        }

        this.setState({
            currentData: currentData["cohort"][month],
            data: currentData,
            options: cohortList,
            defaultOption: cohortList[0],
            dataLoading: false,
        });
        const final = {
            "cohort": {
                "01/02/2023": [],
                "01/03/2023": [],
                "01/05/2023": [],
                "01/09/2023": [],
                "Undefined": [],
            }
        }
    }

    openNewTab = (url) => {
        window.open(url, '_blank');
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        });
    }

    render() {
        return (
            <div className="DashboardVerticalSection">
                <h2>Weekday Distribution of Email Volume</h2>
                <SnackbarError ref={this.snackbarErrorRef} />
                <SnackbarSuccess ref={this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                            loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <div className="HorizontalFlex CenterContentsVerticalInHorizontalFlex">
                        <p className="TitleText">Cohort:</p>
                        <Dropdown options={this.state.options} onChange={this._onSelect} value={this.state.defaultOption} placeholder="Select Cohort" />
                    </div>

                    <br />

                    <ResponsiveContainer width="100%" height="90%">
                        {this.state.currentData && <FrequencyDistributionChart data={this.state.currentData} binCount={20} />}
                    </ResponsiveContainer>
                </div>

            </div>
        );
    }
}


export default WeekdayDistributionOfEmailVolume;
